import {ICoreContext} from '@msdyn365-commerce/core';
import * as React from 'react';

import { euroTax_CAPAsync } from '../../../../actions/CapServices_DataActionExtension.g'
import { getCOValue_CAPAsync } from '../../../../actions/Santander_DataActionExtension.g';
import { ISantanderCOPostResponse_CAP } from '../../../../actions/Santander_DataServiceEntities.g';

interface ICheckoutSideSimulation {
    context:ICoreContext;
    finalPrice:number | undefined;
    confirmDepositAmt:number | undefined;
    itemId:string | undefined;
    itemConfiguration: string | undefined | false;
    deliveryAmount:number | undefined;
    deliveryMode:string | undefined;
    deliveryLabel:string | undefined;
}

export const CheckoutSideSimulation: React.FC<ICheckoutSideSimulation> = ({
    context,
    finalPrice,
    confirmDepositAmt,
    itemId,
    itemConfiguration,
    deliveryAmount,
    deliveryMode,
    deliveryLabel
}) => {
    const deliveryModeName = deliveryMode === 'Ritiro' ? 'Punto di ritiro' : deliveryMode;
    const amountToBeFinanced  = (finalPrice as number) - (confirmDepositAmt as number);

    // Data from User/Local Storage
    const loanStatus          = localStorage.getItem('toggleState');
    const loanType            = localStorage.getItem('loanType');
    const advanceFromUser     = localStorage.getItem('advance');
    const durationFromUser    = localStorage.getItem('duration');
    const rataMensileFromUser = localStorage.getItem('rata-mensile');
    const tanFromUser         = localStorage.getItem('tan');
    const taegFromUser        = localStorage.getItem('taeg');
    const opt                 = loanType === 'Classic' ? 1 : 2;
    /* Manage 24h countdown to emptying localStorage */
    const now    = new Date();
    const expiry = now.getTime() + 86400000;
    if (localStorage.getItem('expiry') && now.getTime() > Number(localStorage.getItem('expiry'))) {
        localStorage.clear();
    }
    // Use data from props
    const [saResultData, setSaResultData]       = React.useState({
        Tan         : '',
        Taeg        : '',
        MonthlyRate : '',
        MaxiRate    : '',
        AmountDue   : '', // .AmountDue = importo totale dovuto
        Interests   : '',
        Flex: {
            duration                : 0,
            durationTotal           : 0,
            monthlyRate             : 0,
            tan                     : 0,
            taeg                    : 0,
            secondPartFirstMonth    : 0,
            maxiRateFinal           : 0,
            monthlyRateSecondPart   : 0,
            tanSecondPart           : 0,
            taegSecondPart          : 0,
        }
    });
    React.useEffect(() => {
       if(loanStatus && loanStatus !== 'closed' && itemId) {
           euroTax_CAPAsync(
               {callerContext: context?.actionContext},
               itemId as string,
               itemConfiguration ? itemConfiguration : '' // data.product.result.ExtensionProperties => dimensions[2] dimensionID
           ).then((euroTax) => {
               getCOValue_CAPAsync(
                   {callerContext: context?.actionContext},
                   itemId as string,
                   opt,
                   'P',
                   '', // orderID on orderdetails, unused elsewhere
                   Number(durationFromUser),
                   !localStorage.getItem('finalPrice') ? Number(amountToBeFinanced) : Number(localStorage.getItem('finalPrice')),
                   finalPrice as number,
                   euroTax.EuroTaxCode as string
               ).then((result:ISantanderCOPostResponse_CAP) => {
                   if (result && result.VarRates && result.VarRates.length === 0) {
                       setSaResultData({
                                           Tan:            result.Tan          as string,
                                           Taeg:           result.Taeg         as string,
                                           MonthlyRate:    result.MonthlyRate  as string,
                                           MaxiRate:       result.MaxiRate     as string,
                                           AmountDue:      result.AmountDue    as string,
                                           Interests:      result.Interests    as string,
                                           Flex: {
                                               duration:               0,
                                               durationTotal:          0,
                                               monthlyRate:            0,
                                               tan:                    0,
                                               taeg:                   0,
                                               secondPartFirstMonth:   0,
                                               maxiRateFinal:          0,
                                               monthlyRateSecondPart:  0,
                                               tanSecondPart:          0,
                                               taegSecondPart:         0,
                                           },
                                       });
                       localStorage.setItem('tan',                 result.Tan              as string);
                       localStorage.setItem('taeg',                result.Taeg             as string);
                   } else if (result && result.VarRates && result.VarRates.length > 0) {
                       setSaResultData({
                                           Tan:            result.Tan          as string,
                                           Taeg:           result.Taeg         as string,
                                           MonthlyRate:    result.MonthlyRate  as string,
                                           MaxiRate:       result.MaxiRate     as string,
                                           AmountDue:      result.AmountDue    as string,
                                           Interests:      result.Interests    as string,
                                           Flex: {
                                               duration:               result.VarRates[0].ARata,
                                               durationTotal:          result.VarRates[1].ARata,
                                               monthlyRate:            result.VarRates[0].ImportoRata,
                                               tan:                    result.VarRates[0].Tan,
                                               taeg:                   result.VarRates[0].Taeg,
                                               secondPartFirstMonth:   result.VarRates[1].DaRata,
                                               maxiRateFinal:          result.VarRates[0].CapitaleResiduo,
                                               monthlyRateSecondPart:  result.VarRates[1].ImportoRata,
                                               tanSecondPart:          result.VarRates[1].Tan,
                                               taegSecondPart:         result.VarRates[1].Taeg,
                                           },
                                       });
                       localStorage.setItem('tan', result.VarRates[0].Tan.toString());
                       localStorage.setItem('taeg', result.VarRates[0].Taeg.toString());
                       localStorage.setItem('max-rata-finale', result?.VarRates[0]?.CapitaleResiduo.toString())
                   }
                   localStorage.setItem('expiry',              expiry.toString());
                   localStorage.setItem('quotaNum',            result.QuotationNumber.toString());
                   localStorage.setItem('productId',           itemId                  as string);
                   localStorage.setItem('rata-mensile',        result.MonthlyRate      as string);
                   localStorage.setItem('secciUrl',            result.SecciUrl         as string);
                   localStorage.setItem('amount-due-total',    result.AmountDue        as string);
                   localStorage.setItem('interests',           result.Interests        as string);
                   localStorage.setItem('confirmDepositAmt',   confirmDepositAmt?.toString() as string);
                   document.querySelector('.financing-container.toggled')?.classList.add('updated');
               });
           });
       }

    }, [itemConfiguration]);

    return (
        <>
            <div className='financing-simulation__table'>
                {deliveryMode !== '' ? (
                    <div className='financing-simulation__table-delivery'>
                        <p className='financing-table__heading-title'>
                            {deliveryLabel}
                        </p>
                        <ul>
                            <li className='no-bd'>
                                <span className='simulation-value'>
                                    {deliveryModeName === 'Punto di ritiro' ? '0': deliveryAmount}€
                                </span>
                                <span className='simulation-label'>
                                    {deliveryModeName}
                                </span>
                            </li>
                        </ul>
                    </div>
                ) : ''
                }
                {localStorage.getItem('toggleState') !== 'closed' && localStorage.getItem('saved') === 'true' ? (
                    <div className='financing-simulation__table-simulation'>
                        <p className='financing-table__heading-title'>Simulazione del finanziamento</p>
                        <ul>
                            <li>
                                <span className='simulation-value'>{advanceFromUser ? Number(advanceFromUser).toLocaleString('it-IT') : 0}€</span>
                                <span className='simulation-label'>Anticipo</span>
                            </li>
                            <li>
                                <span className='simulation-value'>{durationFromUser} MESI</span>
                                <span className='simulation-label'>{localStorage.getItem('loanType') === 'Flex' ? 'Durata primo periodo' : 'Durata del finanziamento'}</span>
                            </li>
                            <li>
                                <span className='simulation-value'>{rataMensileFromUser ? Number(rataMensileFromUser).toLocaleString('it-IT') : saResultData && Number(saResultData.MonthlyRate).toLocaleString('it-IT')}€</span>
                                <span className='simulation-label'>Rata mensile</span>
                            </li>
                            <li>
                                <span className='simulation-value'>{Number(tanFromUser).toLocaleString('it-IT')}%</span>
                                <span className='simulation-label'>Tan</span>
                            </li>
                            <li>
                                <span className='simulation-value'>{Number(taegFromUser).toLocaleString('it-IT')}%</span>
                                <span className='simulation-label'>Taeg</span>
                            </li>
                            {localStorage.getItem('loanType') === 'Flex' ? (
                                <li>
                                    <span className='simulation-value'>{Number(localStorage.getItem('max-rata-finale')).toLocaleString('it-IT')}€</span>
                                    <span className='simulation-label'>Maxi rata finale</span>
                                </li>
                            ) : ''
                            }
                            <li>
                                <span className='simulation-value'>{Number(localStorage.getItem('amount-due-total')).toLocaleString('it-IT')}€</span>
                                <span className='simulation-label'>Importo totale dovuto</span>
                            </li>
                            <li>
                                <span className='simulation-value'>{Number(localStorage.getItem('interests')).toLocaleString('it-IT')}€</span>
                                <span className='simulation-label'>Interessi</span>
                            </li>
                        </ul>
                    </div>
                ) : ''
                }
                <div className='financing-simulation__table-deposit'>
                    <ul>
                        <li className='no-bd'>
                            <span className='simulation-value'>{confirmDepositAmt}€</span>
                            <span className='simulation-label strong'>Deposito Cauzionale</span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};